import React, { useEffect, useState } from "react";
import api from "../apiConfig";

function Dashboard() {
  const [visitordata, setVisitorData] = useState([]);
  const [visitorsearch, setVisitorSearch] = useState("");
  const fetchVisitorData = () => {
    api.get("/visitors").then((res) => {
      setVisitorData(res.data);
    });
  };

  useEffect(() => {
    fetchVisitorData();
  }, []);

  function htmlToCSV(html, filename) {
    var data = [];
    var rows = document.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");

      for (var j = 0; j < cols.length; j++) {
        row.push(cols[j].innerText);
      }

      data.push(row.join(","));
    }

    downloadCSVFile(data.join("\n"), filename);
  }

  function downloadCSVFile(csv, filename) {
    var csv_file, download_link;

    csv_file = new Blob([csv], { type: "text/csv" });

    download_link = document.createElement("a");

    download_link.download = filename;

    download_link.href = window.URL.createObjectURL(csv_file);

    download_link.style.display = "none";

    document.body.appendChild(download_link);

    download_link.click();
  }

  function generateCsv() {
    var html = document.querySelector("table").outerHTML;
    htmlToCSV(html, "visitordata.csv");
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <input type="text" className="form-control my-3" placeholder="Search Visitor" onChange={(e) => setVisitorSearch(e.target.value)} />
          </div>
          <div className="col-md-3 my-auto">
            <button className="btn btn-success" onClick={() => generateCsv()}>
              Download CSV
            </button>
          </div>
        </div>
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Mobile</th>
              <th scope="col">City</th>
            </tr>
          </thead>
          <tbody>
            {visitordata
              .filter((value) => {
                if (visitorsearch == "") {
                  return value;
                } else if (value.uname.toLowerCase().includes(visitorsearch.toLowerCase()) || value.uemail.toLowerCase().includes(visitorsearch.toLowerCase()) || value.ucity.toLowerCase().includes(visitorsearch.toLowerCase())) {
                  return value;
                }
              })
              .map((data) => (
                <tr>
                  <td>{data.uid}</td>
                  <td>{data.uname}</td>
                  <td>{data.uemail}</td>
                  <td>{data.umobile}</td>
                  <td>{data.ucity}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Dashboard;
