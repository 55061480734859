import React from "react";
import { Link } from "react-router-dom";
import logo from "../logo.jpeg";
import expertimage from "../Photos/Header Slider/Expert.svg";
function Navbar({ isDark }) {
  // Get Expert Advice form

  // ******* Close Form *******

  function closeExpertAdviceForm() {
    document.getElementById("get-expert-advice-form").style.display = "none";
  }

  // Display On Scroll
  function displayPopup() {
    document.getElementById("get-expert-advice-form").style.display = "block";
  }

  setTimeout(displayPopup, 200000);

  return (
    <>
      <nav className="navbar navbar-expand-sm fixed-top navbar-light" style={isDark ? { backgroundColor: "rgba(0, 0, 0, 0.2)", zIndex: 2 } : null}>
        <div className="container">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <Link to="/" style={{ cursor: "pointer" }}>
            <img src={logo} width={150} height={75} />
          </Link>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="topnav">
              <Link to="/">Home</Link>
              <Link to="/about-us">About Us</Link>
              <Link to="/project-gallery">Project Gallery</Link>
              <Link to="/contact-us">Contact Us</Link>
            </div>
          </div>
        </div>
      </nav>
      <div className="contact-form-container" id="get-expert-advice-form">
        <form className="contact-form animate">
          <img src={expertimage} />
          <h4>Book Your Expert Consultation Slot</h4>
          <p>Take the first step towards your goals. Get best advise and suggestions from the real-estate experts.</p>
          <input type="text" name="expadvName" placeholder="Enter name" required />
          <input type="email" name="expadvEmail" placeholder="Enter email" required />
          <input type="number" name="expadvPhNum" placeholder="Enter contact number" />
          <input type="text" name="expadvPhNum" placeholder="Enter City" />
          <button type="submit" name="expadvBtn">
            Get Expert Advice
          </button>
          <br />
          <a className="close-expert-advice-form" onClick={closeExpertAdviceForm}>
            Close
          </a>
        </form>
      </div>
    </>
  );
}

export default Navbar;
