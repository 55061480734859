import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import "../style.css";
import img1 from "../Photos/Header Slider/Trident1.jpeg";
import img2 from "../Photos/Header Slider/Trident2.jpeg";
import img3 from "../Photos/Header Slider/Valley1.jpeg";
import img4 from "../Photos/Header Slider/Valley2.jpeg";
import img5 from "../Photos/Header Slider/Valley3.jpeg";

function Home() {
  // ******* Open Form *******
  function openExpertAdviceForm() {
    document.getElementById("get-expert-advice-form").style.display = "block";
  }

  // ******* Close Form *******

  function closeExpertAdviceForm() {
    document.getElementById("get-expert-advice-form").style.display = "none";
  }
  return (
    <>
      <Navbar />
      <div className="header-slider">
        <figure>
          <img src={img2} alt="" />
          <img src={img4} alt="" />
          <img src={img1} alt="" />
          <img src={img3} alt="" />
          <img src={img5} alt="" />
        </figure>
        <div className="header-slider-button-container">
          <button className="btn btn-secondary" onClick={openExpertAdviceForm}>
            Enquire Now !!
          </button>
        </div>
        <div class="header-slider-text-container">
          <form>
            <h2>Enquire Now !</h2>
            <div class="form-group">
              <input type="text" class="form-control mb-3" placeholder="Enter Name" />
              <input type="email" class="form-control mb-3" placeholder="Enter Email" />
              <input type="number" class="form-control mb-3" placeholder="Enter Phone" maxLength={10} />
              <input type="text" class="form-control mb-3" placeholder="Enter City" />
            </div>
            <button type="submit" class="btn btn-secondary">
              Get in touch
            </button>
          </form>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-sm-12">
            {/* <h2 className="small-border"> */}
              <div>
                PRIME PROPERTIES  is a leading real estate organization having presence in India at Chandigarh. Established in 2010 in the name of Prime Properties has evolved as one of the leading Real Estate Organization dealing in 
                Residential & Commercial Projects with a wide portfolio of business interest in the field of real estate. Since our establishment our goal has been to provide our customers with our quality services & provider with 
                premium residential & commercial properties that are at par with the best in the world. With our decades of experience &  refined expertise, we at Prime Properties.  provide our clients with a selection of properties 
                to the best of their satisfaction. With progressive methods & modern systems, we aim to set benchmarks in the industry for providing quality services in a professional and ethical manner to satisfy our customers as 
                well as our associates. It is in their satisfaction that we see & measure our success.
              </div>
            {/* </h2> */}
            <div className="list_box">
              <div className="list_ancher">
                <Link to="about-us">
                  At Prime Properties, we take pride in our uncompromising integrity in customer engagement and quality assurance, and throughout our... <span>Read More</span>
                </Link>
              </div>
            </div>
          </div>

          {/* <div className="col-sm-1"></div>
          <div className="col-sm-5">
            <div className="info_img text-right">
              <ul className="hppy home_line">
                <li className="down_counter_top">
                  <span className="counter one" style={{ left: 0 }}>
                    <small>75</small>
                  </span>
                  years of sustained <br />
                  growth and innovation
                </li>
                <li className="down_counter down_count_align">
                  <span className="counter two" style={{ left: 0 }}>
                    <small>31.25</small>
                  </span>
                  million sq m <br />
                  delivered
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="ground_text">
              {/*
              <h4 className="small_border">
                Looking for something specific? We'd be <span className="delhihgt">delighted</span> to help you.
              </h4>

               <div className="list_box help_bt">
                <div className="list_ancher">
                  <a href="https://www.dlf.in/business-enquiry.php" title="DLF Business enquiry">
                    <span>Business Enquiry</span>
                  </a>
                </div>

                <div className="list_ancher">
                  <a href="https://www.dlf.in/customer-Support.php" title="DLF Customer support">
                    <span>Customer Support</span>
                  </a>
                </div>

                <div className="list_ancher">
                  <a href="https://www.dlf.in/media.php" title="DLF Media">
                    <span>Media</span>
                  </a>
                </div>

                <div className="list_ancher">
                  <a href="https://www.dlf.in/career-page.php" title="DLF Career">
                    <span>Careers</span>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
