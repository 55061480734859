import React from "react";
import one from "../../Photos/Header Slider/theValleyGarden/1.jpg";
import two from "../../Photos/Header Slider/theValleyGarden/2.jpg";
import three from "../../Photos/Header Slider/theValleyGarden/home.jpg";
import four from "../../Photos/Header Slider/theValleyGarden/layout1.jpg";
import five from "../../Photos/Header Slider/theValleyGarden/layout2.jpg";
import six from "../../Photos/Header Slider/theValleyGarden/layout3.jpg";
import seven from "../../Photos/Header Slider/theValleyGarden/layout4.jpg";
import eight from "../../Photos/Header Slider/theValleyGarden/layout5.jpg";

function Thevalleygardens() {
  return (
    <>
      <div className="bg-valley-gardens"></div>
      <div className="container content-sub-contain">
        <div className="left-detail-box">
          <div className="main-desc-course">
            <div className="what-you-learn">
              <h3>What you'll get</h3>
              <div style={{ width: "45%", float: "left" }}>
                <ul>
                  <li>Independent Floors style 3 & 4 BHK with study</li>
                  <li>Basement + Stilt + 4 floors</li>
                  <li>Tree-lined streets and easy access to green spaces</li>
                  <li>Lift</li>
                </ul>
              </div>
              <div style={{ width: "45%", float: "left", marginLeft: "7%" }}>
                <ul>
                  <li>Access to green spaces</li>
                  <li>Safe & secure community</li>
                  <li>Fully fitted modular kitchen with appliances*</li>
                  <li>Security Camera in stilt and lobby</li>
                </ul>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="gallary">
              <h3>Project Images</h3>
              <div className="row">
                <div className="col-md-4">
                  <img src={one} />
                </div>
                <div className="col-md-4">
                  <img src={two} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <img src={three} />
                </div>
                <div className="col-md-4">
                  <img src={four} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <img src={five} />
                </div>
                <div className="col-md-4">
                  <img src={six} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <img src={seven} />
                </div>
                <div className="col-md-4">
                  <img src={eight} />
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="description">
              <h4>Overview</h4>
              <p>
                Only 13 kms from Chandigarh, but miles away from congestion and pollution, the well established The Valley community sits peacefully against the mountainous backdrop.
                <br />
                <br />
                Designed and crafted to suit modern family living, the highly sought after 70-hectare development, with members-only club and an abundance of green spaces is home to over 1,400 happy families.
              </p>
            </div>
            <br />
            <div className="brouchre">
              <h4>Download Brochure</h4>
              <br />
              <iframe src="https://primeproperties.net.in/Brochure/The%20Valley%20Gardens.pdf" width={600} height={400} frameborder="0"></iframe>
            </div>
          </div>
        </div>
        <div className="price-box">
          <div className="price-box-details">
            <form>
              <h2>Enquire Now !</h2>
              <div class="form-group">
                <input type="text" class="form-control mb-3" placeholder="Enter Name" />
                <input type="email" class="form-control mb-3" placeholder="Enter Email" />
                <input type="number" class="form-control mb-3" placeholder="Enter Phone" maxLength={10} />
                <input type="text" class="form-control mb-3" placeholder="Enter City" />
              </div>
              <button type="submit" class="btn btn-secondary">
                Get in touch
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Thevalleygardens;
