import React from "react";
import Navbar from "./Navbar";
import "../style.css";
function AboutUs() {
  return (
    <>
      <Navbar />
      <div className="banner_inner bussines_banner aboutBanner">
        <div className="banner_text sust_hght1 mobile_abouts">
          <div className="container">
            <div className="banner_content inherits">
              <div>
                <span>
                  <span className="line"></span>About The Company
                </span>

                <p className="first_paragraph">
                  <b>Prime Properties</b> is a leading real estate organization having presence in India at Chandigarh. Established in 2010 in the name of Prime Properties has evolved as one of the leading Real Estate Organization dealing in Residential & Commercial Projects with a wide portfolio of
                  business interest in the field of real estate. Since our establishment our goal has been to provide our customers with our quality services & provider with premium residential & commercial properties that are at par with the best in the world. With our decades of experience &
                  refined expertise, we at Prime Properties. provide our clients with a selection of properties to the best of their satisfaction. With progressive methods & modern systems, we aim to set benchmarks in the industry for providing quality services in a professional and ethical manner
                  to satisfy our customers as well as our associates. It is in their satisfaction that we see & measure our success.
                </p>

                <p className="aboutborder">
                  <b>Our Mission</b>
                  <br />
                  <b>FOR CUSTOMERS:</b>
                  <br />
                  To deliver products & services benchmarked around quality, aspirations and cost; to achieve this via the best of innovations and technology.
                </p>

                <p>
                  <b>FOR INVESTORS:</b>
                  <br />
                  To accomplish quantum growth year-on-year for revenues, profitability and market share; to deliver optimum returns on investor's investment.
                </p>

                <p>
                  <b>FOR EMPLOYEES:</b>
                  <br />
                  To attract and retain talent; maximize the potential of staff and care for their personal wellbeing and career growth.
                </p>
                <p>
                  <b>FOR PARTNERS:</b>
                  <br />
                  To select partners based on the quality of work and their ability to complement our commitment to the environment, health and safety of all people associated with our businesses. We deals with all leading Real Estate developers like: DLF Ltd, Trident, OMAXE, Godrej, M3M and many
                  more.
                </p>
                <p>
                  <b>Services:</b>
                  <br />
                  Our services extend beyond the mere provision of properties to clients. We understand the problems and concerns of potential property buyers when it comes to investing in properties, we seek to address these concerns by applying our vast experience and expertise in the real estate
                  arena and helping customers identify the properties of their choice & understand its viability in terms of parameters such as location, budget, etc.
                </p>

                <p style={{ paddingBottom: "20px" }}>
                  <b>Marketing Services:-</b>
                  <br />
                  <ul>
                    <li>Selling residential & commercial units.</li>
                    <li>Lease and Renting all kind of spacing.</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
