import React, { useState } from "react";
import Navbar from "./Navbar";
import "../style.css";

function ContactUs() {
  const [visitordetails, setVisitorDetails] = useState();

  return (
    <>
      <Navbar />
      <div class="banner_inner customer_inner">
        <div class="container">
          <div class="text-center">
            <h4>Customer Support</h4>
            <span>Have a question for us? Drop us a message and we'll be in touch.</span>
          </div>
        </div>
      </div>

      <div class="contact_wrapper mobile_inverse">
        <div class="container">
          <div class="row mobile_work">
            <div class="col-lg-4 col-md-12">
              <div class="contact_map">
                <div class="contact_dlf">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3428.574396978317!2d76.91366831513211!3d30.758454381628212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDQ1JzMwLjQiTiA3NsKwNTQnNTcuMSJF!5e0!3m2!1sen!2sin!4v1679110944047!5m2!1sen!2sin"
                    width="100%"
                    height="350"
                    frameborder="0"
                    allowfullscreen
                    style={{ border: 0 }}
                  ></iframe>
                </div>

                <div class="contact_address">
                  <h4>Corporate Office</h4>
                  <p style={{ color: "white" }}>
                    Prime Properties <br /> Flat No-B1/34-GF, <br /> Dlf Valley, Near Amravati Enclave,
                    <br /> Panchkula, Haryana-134107
                  </p>
                  <p style={{ color: "white" }}>+91-73411 55500</p>
                  <p style={{ color: "white" }}>+91-73411 55400</p>
                </div>
              </div>
            </div>

            <div class="col-lg-8 col-md-12">
              <div class="contact_form">
                <p class="success" id="customer_support_success"></p>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group right_group">
                        <input type="text" class="form-control only-alpha" name="fname" id="customer_support_name" placeholder="Name" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group left_group">
                        <input type="text" class="form-control only-alpha" name="lname" id="customer_support_lname" placeholder="City" />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <input type="text" class="form-control" name="email" id="customer_support_email" placeholder="E-MAIL ADRESS" />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <input type="text" class="form-control only-numeric" name="phone" id="customer_support_phone" placeholder="PHONE" maxlength="10" />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <textarea class="form-control" rows="14" name="message" id="customer_support_message" placeholder="MESSAGE"></textarea>
                    </div>
                    <input type="hidden" name="business_type" id="customer_support_business_type" value="1" />
                    <input type="hidden" name="sub_type" value="2" id="customer_support_sub_type" />

                    <div class="col-md-12">
                      <div class="text-right">
                        <p class="error" id="customer_support_error"></p>
                        <button type="button" name="submit" id="submit" class="btn-dlf hvr-sweep-to-right" onclick="submit_query('customer_support')">
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
