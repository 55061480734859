import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Admin from "./components/Admin";
import Dashboard from "./components/Dashboard";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import Projectgallery from "./components/Projectgallery";
import Dlfvalley from "./components/Project Subpages/Dlfvalley";
import Thevalleygardens from "./components/Project Subpages/Thevalleygardens";
import Tridenthills from "./components/Project Subpages/Tridenthills";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/projects/trident-hills" element={<Tridenthills />} />
        <Route exact path="/projects/the-valley-gardens" element={<Thevalleygardens />} />
        <Route exact path="/projects/dlf-valley" element={<Dlfvalley />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/project-gallery" element={<Projectgallery />} />
        <Route exact path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
