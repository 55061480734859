import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import "../style.css";
import img1 from "../Photos/Header Slider/Trident1.jpeg";
import img2 from "../Photos/Header Slider/Trident2.jpeg";
import img3 from "../Photos/Header Slider/Valley1.jpeg";
import img4 from "../Photos/Header Slider/Valley2.jpeg";
import img5 from "../Photos/Header Slider/Valley3.jpeg";

import home from "../Photos/Header Slider/theValleyGarden/home.jpg";
import vg1 from "../Photos/Header Slider/theValleyGarden/1.jpg";
import vg2 from "../Photos/Header Slider/theValleyGarden/2.jpg";

function Projectgallery() {
  // ******* Open Form *******
  function openExpertAdviceForm() {
    document.getElementById("get-expert-advice-form").style.display = "block";
  }
  return (
    <>
      <Navbar />
      <div className="our-projects">
        <h1>Projects</h1>
        <div className="row">
          <div className="col-md-4">
            <div className="card">
              <div className="container-2">
                <figure>
                  <img src={home} />
                  <img src={vg1} />
                  <img src={vg2} />
                </figure>
                <div className="clearfix"></div>
                <h4>
                  <b>The Valley Garden</b>
                </h4>
                <Link to="/projects/the-valley-gardens" className="btn btn-secondary">
                  Enquire Now
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card">
              <div className="container">
                <figure>
                  <img src={img1} />
                  <img src={img2} />
                </figure>
                <h4>
                  <b>The Trident Hills</b>
                </h4>
                <Link to="/projects/trident-hills" className="btn btn-secondary">
                  Enquire Now
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card">
              <div className="container-2">
                <figure>
                  <img src={img3} />
                  <img src={img4} />
                  <img src={img5} />
                </figure>
                <div className="clearfix"></div>
                <h4>
                  <b>DLF Valley</b>
                </h4>
                <Link to="/projects/dlf-valley" className="btn btn-secondary">
                  Enquire Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Projectgallery;
