import React from "react";
import one from "../../Photos/Header Slider/trident/1.jpg";
import two from "../../Photos/Header Slider/trident/2.jpg";
import three from "../../Photos/Header Slider/trident/3.jpg";
import four from "../../Photos/Header Slider/trident/4.jpg";
import five from "../../Photos/Header Slider/trident/5.jpg";

function Tridenthills() {
  return (
    <>
      <div className="bg-trident-hills"></div>
      <div className="container content-sub-contain">
        <div className="left-detail-box">
          <h1>Trident Hills</h1>
          <div className="main-desc-course">
            <div className="what-you-learn">
              <h3>What you'll get</h3>
              <div style={{ width: "45%", float: "left" }}>
                <ul>
                  <li>Green & open area</li>
                  <li>Club house</li>
                  <li>Herb Garden</li>
                  <li>Smart Street Lights</li>
                  <li>Electric Car & Charging Point</li>
                </ul>
              </div>
              <div style={{ width: "45%", float: "left", marginLeft: "7%" }}>
                <ul>
                  <li>Control & Command Center</li>
                  <li>CCTV Surveillance</li>
                  <li>Medical Facilities</li>
                  <li>Education Facilities</li>
                  <li>Power Backup</li>
                </ul>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="gallary">
              <h3>Project's Actual Images</h3>
              <div className="row">
                <div className="col-md-4">
                  <img src={one} />
                </div>
                <div className="col-md-4">
                  <img src={two} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <img src={three} />
                </div>
                <div className="col-md-4">
                  <img src={four} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <img src={five} />
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="description">
              <h4>Overview</h4>
              <p>
                TRIDENT HILLS is a 200-acre (80.94 Ha) approx., integrated township located at the foothills of Shivaliks offering range of residential & commercial real estate products.
                <br />
                <br />
                TRIDENT HILLS offers Residential plots, Low-rise premium floors, Villas, Group Housing & Commercial space. TRIDENT HILLS is spread over 200 acres Approx. TRIDENT HILLS is situated at Sector - 4, Panchkula-Kalka urban complex, Pinjore, Panchkula (Haryana)- 134102
                <br />
                <br />
                TRIDENT HILLS - Residential plots starts from Rs. 1.80 Cr.* Terms & conditions apply.
              </p>
            </div>
          </div>
        </div>
        <div className="price-box">
          <div className="price-box-details">
            <form>
              <h2>Enquire Now !</h2>
              <div class="form-group">
                <input type="text" class="form-control mb-3" placeholder="Enter Name" />
                <input type="email" class="form-control mb-3" placeholder="Enter Email" />
                <input type="number" class="form-control mb-3" placeholder="Enter Phone" maxLength={10} />
                <input type="text" class="form-control mb-3" placeholder="Enter City" />
              </div>
              <button type="submit" class="btn btn-secondary">
                Get in touch
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tridenthills;
