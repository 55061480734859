import React from "react";
import one from "../../Photos/Header Slider/dlfValley/1.jpg";
import two from "../../Photos/Header Slider/dlfValley/2.jpg";
import three from "../../Photos/Header Slider/dlfValley/3.jpg";
import four from "../../Photos/Header Slider/dlfValley/4.jpg";
import five from "../../Photos/Header Slider/dlfValley/5.jpg";
import six from "../../Photos/Header Slider/dlfValley/6.jpg";
import seven from "../../Photos/Header Slider/dlfValley/7.jpg";
import eight from "../../Photos/Header Slider/dlfValley/8.jpg";
import nine from "../../Photos/Header Slider/dlfValley/9.jpg";

function DlfValley() {
  return (
    <>
      <div className="bg-valley"></div>
      <div className="container content-sub-contain">
        <div className="left-detail-box">
          <h1>DLF Valley</h1>
          <div className="main-desc-course">
            <div className="what-you-learn">
              <h3>What you'll get</h3>
              <div style={{ width: "45%", float: "left" }}>
                <ul>
                  <li>STATE-OF-THE-ART GYMNASIUM</li>
                  <li>SWIMMING & SPLASH POOL</li>
                  <li>YOGA CENTRE</li>
                  <li>LIBRARY & READING ROOM</li>
                </ul>
              </div>
              <div style={{ width: "45%", float: "left", marginLeft: "7%" }}>
                <ul>
                  <li>RESTAURANT</li>
                  <li>CHILDREN'S PLAY AREA</li>
                  <li>CARD ROOM</li>
                  <li>BILLIARD ROOM</li>
                </ul>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="gallary">
              <h3>Project's Actual Images</h3>
              <div className="row">
                <div className="col-md-4">
                  <img src={one} />
                </div>
                <div className="col-md-4">
                  <img src={two} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <img src={three} />
                </div>
                <div className="col-md-4">
                  <img src={four} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <img src={five} />
                </div>
                <div className="col-md-4">
                  <img src={six} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <img src={seven} />
                </div>
                <div className="col-md-4">
                  <img src={eight} />
                </div>
                <div className="col-md-4">
                  <img src={nine} />
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="description">
              <h4>Overview</h4>
              <p>
                Minutes away from Chandigarh and miles away from congestion and pollution, The Valley offers the best of both worlds. Spread across 84 Hectares, The Valley is a well-planned township with future-ready infrastructure and dedicated resources for natural living and leisure.
                <br />
                <br />
                Located in the foothills of the Shivalik Range, this one-of-a-kind community is home to 1100 happy families. Features like Nursery, Primary & High School in the township, world-class medical facility, round-the-clock water and power supply, water recycling system, shopping and
                recreational avenues in close proximity add to the unique experience.
              </p>
            </div>
          </div>
        </div>
        <div className="price-box">
          <div className="price-box-details">
            <form>
              <h2>Enquire Now !</h2>
              <div class="form-group">
                <input type="text" class="form-control mb-3" placeholder="Enter Name" />
                <input type="email" class="form-control mb-3" placeholder="Enter Email" />
                <input type="number" class="form-control mb-3" placeholder="Enter Phone" maxLength={10} />
                <input type="text" class="form-control mb-3" placeholder="Enter City" />
              </div>
              <button type="submit" class="btn btn-secondary">
                Get in touch
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default DlfValley;
